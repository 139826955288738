import { PrismicLink } from "@prismicio/react";
import Link from "next/link";

const IsLinkWrapper = ({ children, isLive, test, ...props }) =>
  isLive ? (
    <PrismicLink field={test} {...props}>
      {children}
    </PrismicLink>
  ) : (
    <div {...props}>{children}</div>
  );
function TestCardAction({ test, orderData }: { test; orderData }) {
  const { data } = test;
  const isLive = data.is_live;
  const testUid = test.uid;
  // const { data: orderData } = useOrders({ testUid });
  // log(chalk.bgMagenta("ORDER DATA: "), { testUid, orderData });
  return (
    <div className="flex  border-t border-stone-200">
      <IsLinkWrapper
        isLive={isLive}
        test={test}
        className="w-full lg:w-1/2 flex-grow p-4 text-center text-xs font-bold font-util uppercase tracking-wider"
      >
        {orderData ? `Take Test` : `Learn More`}
      </IsLinkWrapper>
      {orderData ? null : (
        <Link
          href={{
            pathname: "/checkout",
            query: {
              test_uid: testUid,
              redirect: testUid,
            },
          }}
          passHref
          className="w-full lg:w-1/2 p-4 text-center border-l border-stone-200 bg-accent text-xs font-bold font-util uppercase tracking-wider"
        >
          Purchase
        </Link>
      )}{" "}
    </div>
  );
}

export default TestCardAction;
