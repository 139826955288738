import TestCard from "@/components/TestCard";
import useOrders from "@/lib-client/react-query/orders/useOrders";
import { chalk, log } from "@/utils/log";

function SectionFeaturedtestsCard({ featuredTests }: { featuredTests: any }) {
  const { data: ordersData } = useOrders();
  log(chalk.magentaBright("Home => Orders:"), ordersData);
  return (
    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg  sm:px-8 lg:px-16 lg:grid-cols-2 lg:max-w-6xl">
      {featuredTests.map(({ test }, i) => {
        return <TestCard key={i} test={test} ordersData={ordersData} />;
      })}
    </div>
  );
}
export default SectionFeaturedtestsCard;
