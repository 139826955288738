import { useSessionContext } from "@supabase/auth-helpers-react"
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useMe } from "@/lib-client/providers/Me"
import { OrdersGetData } from "@/types/models/Order"
import { chalk, log } from "@/utils/log"

import { useGetMe } from "../auth/useGetMe"
import QueryKeys, { filterEmptyQueryKeys } from "../queryKeys"
import { supabaseClient } from "../supabase"




const getOrders = async ({ userId, testUid }: { userId: string, testUid?: string }) => {
    const query = supabaseClient.from('orders').select('id, test_uid, created_at, order_status').eq("user_id", userId)
    if (testUid) {
        query.eq("test_uid", testUid)
    }
    //get the lastest updated first
    query.order('updated_at', { ascending: false })
    const { data } = await query
    return data
}

const useOrders = (params = {} as OrdersGetData) => {
    const { me, isLoading } = useMe()
    // const { data: user, isLoading } = useGetMe()
    //make it depended on me.id
    //because otherwise suspense waterfall problem
    const queryClient = useQueryClient()
    const { testUid } = params
    const userId = me?.id ?? null
    log(chalk.redBright('useOrders'), { userId, })
    return useQuery({
        queryKey: filterEmptyQueryKeys([QueryKeys.ORDERS, userId, testUid]),
        queryFn: () => getOrders({ userId, testUid }),
        enabled: !!userId && !isLoading,
        onSuccess: (orders) => {
            orders.forEach((order) => {
                // const prevData = queryClient.getQueryData([QueryKeys.ORDERS, userId, order.test_uid])
                queryClient.setQueryData<Array<typeof order>>([QueryKeys.ORDERS, userId, order.test_uid], (prevData) => {

                    if (prevData) {
                        prevData.push(order)
                        return prevData
                    }
                    return [order]
                })
                queryClient.setQueryData([QueryKeys.ORDER, userId, order.id], order)
            })
        }
    })

}
export default useOrders